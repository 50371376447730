// import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
// import ReactDOM from 'react-dom';
import React from 'react';
import './routes.css';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';

import NavBar from './components/navBar/navBar';
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Products } from "./components/Pages/Products";
import { Contact } from "./components/Pages/Contact";
import Services from "./components/Pages/Services";
import { ContactForm } from "./components/Pages/ContactForm";


// const scrollToRef = (ref) => window.scroll(0,ref.current.offsetTop)

export default function Routes() {


    // const myRef =useRef(null)
    // const executeScroll= () => scrollToRef(myRef)
    return (


        <Router>
            <NavBar />
            <div className="pages">
                <Switch>
                    <Route exact path="/" render={props => (
                        <React.Fragment>
                            {/* <div>
                            <Home ref={h} />
                           </div> */}
                            {/* <Home/> */}
                            <About />
                            <Services />
                            <Home />
                            <Products />
                            <ContactForm />
                            <Contact />
                        </React.Fragment>
                    )} />
                </Switch>
            </div>
        </Router>

    )

}



import React from "react";
import './Contact.css';
import fb from '../assets/facebook.png';
import link from '../assets/linkedin.png';
import twitter from '../assets/twitter.png';
import ignite from '../assets/ignite.jpg';
import event2 from '../assets/event2.jpg';
// import event1 from '../assets/im1.png';
// import event3 from '../assets/im2.png';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const Contact = () => {
    return (
        <div id="contactId">
            <br /> <br />
            <br /><br />
            <br /><br />
            <br /><br />
            <br />

            <div style={{ borderTop: '1px solid rgba(106, 58, 188, 0.19)' }}>

                <Container>
                    <Row>
                        <Col></Col>
                        <Col sm={2} className="col-md-3">
                            <br/>
                            <div className="">
                                {/* Taiba */}
                                <a href="https://web.facebook.com/media/set/?vanity=NICPakistan&set=a.3349315358417144" target="_blank">
                                    <img src={event2} style={{ width: '110%',borderRadius: '12px' }} className="achievementSection1" />
                                    <br />
                                    <br />
                                    <h5 className="igniteHead"> NIC Graduation (surilli.IO-cohort 4) 2020 by NIC</h5>
                                </a>
                            </div>               


                        </Col>
                        <Col>
                        </Col>
                        


                        <Col sm={2}  className="col-md-3">
                            <div className="">
                                {/* Taiba */}
                                <a href="https://propakistani.pk/2021/04/12/agrisurge-innovation-challenge-to-digitize-pakistans-agriculture-sector/?fbclid=IwAR2KZ6dbFyY-RaxoRHI6RZmmDWArEOhqx8Gj09WVtJyTp4WIpWUcv8zGurw" target="_blank">
                                    <img src={ignite} style={{ width: '110%', borderRadius: '12px' }} className="achievementSection" />
                                    <br />
                                    <br />

                                    <h5 className="igniteHead"> AgriSurge Innovation Challenge 2020 by Ignite</h5>
                                </a>
                            </div>

                        </Col>

                        <Col>
                        </Col>



                        <Col  className="col-md" sm={2}>
                            {/* <div className="vl"></div> */}
                            <br/>
                            <br/>

                            <div className="footerPart2" >


                                <ul style={{ listStyleType: 'none' }}>


                                    <li>
                                        <a href="hello@silver-back.com" className="mailCheck">
                                            hello@silverback.com.pk
                                        </a>
                                    </li>
                                    <br />
                                    <li>
                                        <div className="addressSilverback">
                                            <ion-icon name="location-outline"></ion-icon>

                                            &nbsp; 2nd Floor, Oskar Center, E-11/2 Markaz, <br />
                                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Northern Strip,
                                             Plot No.41, FECHS,<br/>
                                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Islamabad, Pakistan.
                                        </div>
                                    </li>
                                    <br />
                                    <li style={{ color: '#555' }} className="phone">
                                        <ion-icon name="call-outline"></ion-icon>
                                        &nbsp;&nbsp;&nbsp;&nbsp;051-2318058<br/>
                                        {/* <ion-icon name="phone-portrait"></ion-icon> */}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+92344-8690139


                                    </li>
                                    {/* <li></li> */}
                                    <br />
                                     <li>
                                        <a href="https://www.facebook.com/SilverbackPvtLtd/">
                                            <img src={fb} alt="facebook" className="hover" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/silverback-pvt.-ltd/">
                                            <img src={link} alt="linkedin" className="hover" />
                                        </a>
                                        <a href="https://twitter.com/Silverback_PK">
                                            <img className="hover" src={twitter} alt="twitter" />
                                        </a>
                                    </li>
                                   
                                </ul>

                            </div>


                        </Col>




                    </Row>

                </Container>

                {/* <div className="footer">

                    <br />
                    <br />
                    <br />

                    <ul className="social-networks" style={{ listStyleType: 'none' }}>
                        <li>
                            <a href="https://www.facebook.com/SilverbackPvtLtd/">
                                <img src={fb} alt="facebook" className="hover" />
                            </a>
                        </li>
                        <br />

                        <li>
                            <a href="https://www.linkedin.com/company/silverback-pvt.-ltd/">
                                <img src={link} alt="linkedin" className="hover" />
                            </a>
                        </li>
                        <br />

                        <li>
                            <a href="https://twitter.com/arbisoft">
                                <img className="hover" src={twitter} alt="twitter" />
                            </a>
                        </li>
                    </ul>

                </div>
 */}
                {/* <div className="achievementSection">
                    Taiba
                    <img src={ignite} />
                </div> */}

                {/* <div className="vl"></div> */}


                {/* <div className="footerPart2" >

                    <ul style={{ listStyleType: 'none' }}>


                        <li>
                            <a href="hello@silver-back.com" className="mailCheck">
                                hello@silver-back.com
                            </a>
                        </li>
                        <br />                        <li>
                            <div className="addressSilverback">
                                2nd Floor, Oskar Center, E-11/2, Northern Strip,<br /> Plot No.41, FECHS,
                                Islamabad, Pakistan.
                            </div>
                        </li>
                        <br />
                        <li style={{ color: '#555' }}>
                            Phone: 0337-7874554
                        </li>
                        <li></li>
                    </ul>

                </div>
 */}



            </div>
            <br />

            <div className="footer_copy">
                <div>
                    <div className="container">
                        <div className="column one">

                            {/* <a id="back_to_top" href="#" className="button button_js">
                                <i className="icon-up-open-big"></i>
                            </a> */}
                            {/* <!-- Copyrights --> */}
                            <div className="copyright">
                                © 2021 Silverback. All Rights Reserved.
                            </div>

                            {/* <div>
                                <a id="back_to_top" className="button button_js" href="">
                                    <i className="icon-up-open-big"></i>
                                </a>
                            </div> */}

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}
import React, { useState } from 'react';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import './navBar.css';
import logo from '../assets/logo.png';
import Slider from '../slider/slider';
import Services from '../Pages/Services';




export default function NavBar() {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <div>
            <nav className="navbar">
                <div className="nav-container">
                    <NavLink exact to="/" className="nav-logo">
                        {/* SILVERBACK */}
                        <img src={logo} alt="logo" />
                    </NavLink>

                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink exact to="/" activeClassName="active" className="nav-links" onClick={() => setClick(!click)} style={{textDecoration:'none'}}> 
                                <Link to="homeId" spy={true} smooth={true} style={{textDecoration:'none'}}>
                                        HOME
                                </Link>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink exact to="/" activeClassName="active" className="nav-links" onClick={handleClick} style={{textDecoration:'none'}}>
                                <Link to="aboutId" spy={true} smooth={true} style={{textDecoration:'none'}}>
                                    ABOUT US
                                </Link>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink exact href={Services} to="" activeClassName="active" className="nav-links" onClick={handleClick} style={{textDecoration:'none'}}>
                                <Link to="servicesId" spy={true} smooth={true} style={{textDecoration:'none'}}>
                                    SERVICES
                                </Link>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to="/" activeClassName="active" className="nav-links" onClick={handleClick} style={{textDecoration:'none'}}>
                                <Link to="productsId" spy={true} smooth={true} style={{textDecoration:'none'}}>
                                    PRODUCTS
                                </Link>
                            </NavLink>
                        </li>
                   
                        <li className="nav-item">
                            <NavLink exact to="/" activeClassName="active" className="nav-links" onClick={handleClick} style={{textDecoration:'none'}}>
                                <Link to="contactId" spy={true} smooth={true} style={{textDecoration:'none'}}>
                                    CONTACT
                                </Link>
                                {/* CONTACT */}
                            </NavLink>
                        </li>
                    </ul>

                    <div className="nav-icon" onClick={handleClick}>
                        <ion-icon name={click ? "close-outline" : "menu-outline"}></ion-icon>
                    </div>


                </div>
            </nav>
            <Slider />
        </div>

    );
}





import React from 'react';
import './slider.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import Carousel from 'nuka-carousel';
// import surilli from '../assets/surillimaingif2.jpg';
// import banner1 from '../assets/banner3.png';
import banner from '../assets/Silver.gif';





export default function Slider() {

    return (
        <div id="homeId">
            <br/>
            <br/>
            {/* <Carousel> */}
                <div>
                    <img src={banner} alt="silverBackBanner" className="silverBackBanner"/>
                    {/* <div className="top-left">We wants to be your technology partner.</div> */}
                </div>
                <div>
                    {/* <img src={surilli} alt="silverBackBanner" className="silverBackBanner"/> */}
                </div>
                <div>
                    {/* <img src={surilli} alt="silverBackBanner" className="silverBackBanner"/> */}
                </div>
            {/* </Carousel> */}
        </div>

    );
}





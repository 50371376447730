import React from "react";
import './ContactForm.css';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const countryList = [
    "Select your Country",
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Britain and Northern Ireland",
    "United States Minor Outlying Islands",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

export const ContactForm = () => {
    return (
        <div>
            <br />
            <br />

            <div className="container">
                <h4 className="relationHeadCF">Get in touch today to discover what we can do for you</h4>
                {/* <p className="relationParaCF">Beyond our skilled and expertise, it is our customers 
                and our relationship with them that sets us apart.
                </p> */}
                <br />
                <br />


                <div className="form">
                    <input type="text" className="item1" placeholder="Your Name" />
                    <input type="email" className="item2" placeholder="Your Email" />
                    <br />
                    <br />
                    <br />
                    {/* <br/> */}
                    <input type="phone" className="item1" placeholder="Your Phone (Optional)" />
                    <input ty r43212345pe="text" className="item2" placeholder="Your Company/Organization (Optional)" />
                    <br />
                    <br />
                    <br />

                    <Row>
                        <Col >
                            <div className="_field-wrapper">
                                <select name="field[11]" id="field[11]" required="" data-name="which_services_are_you_interested_in">
                                    <option selected="">Which Service Are You Interested In?
                                    </option>
                                    <option value="Enterprise Software Solutions">
                                        Enterprise Software Solutions
                                    </option>
                                    {/* <option value="Dedicated Development Team">
                                        Dedicated Development Team
                                    </option> */}
                                    <option value="Mobile App Development">
                                        Mobile App Development
                                    </option>
                                    <option value="Web App Development">
                                        Web App Development
                                    </option>
                                    <option value="UI/UX">
                                        UI/UX
                                    </option>
                                    <option value="Data Services">
                                        Data Services
                                    </option>
                                    <option value="Quality Assurance">
                                        Quality Assurance
                                    </option>
                                    <option value="3D App Development">
                                        PCB Designing
                                    </option>
                                    <option>
                                        Hardware Integration 
                                    </option>
                                    <option value="Quality Assurance">
                                        Communication Protocol
                                    </option>


                                </select>
                            </div>
                        </Col>

                        <Col>
                            <div className="_field-wrapper1">
                                <select name="field[11]" id="field[11]" required="" data-name="which_services_are_you_interested_in">
                                    {countryList.map((el, index) => {
                                        return (
                                            <option>
                                                {el}
                                            </option>
                                        )
                                    })}
                                </select>

                            </div>


                        </Col>
                    </Row>

                    <br />
                    <br />


                    <div className="_field-wrapper">
                        <textarea id="field" name="field[6]" placeholder="Let us know a bit more about the project you have in mind..." required="" data-name="message"></textarea>
                    </div>

                    <br />

                    {/* <div style={{ display: 'flex' }}>
                        <input type="checkbox" className="check" />
                        <p>Please send me a non-disclosure agreement</p>

                    </div> */}

                    <br />

                    <div className="_button-wrapper _full_width">
                        <button id="submitbtn" className="_submit" type="submit">
                            LET'S START A CONVERSATION
                        </button>
                    </div>

                </div>

            </div>


        </div>
    );
}
import React from "react";
import './Home.css';
import skillset from '../assets/icons.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Image from 'react-bootstrap/Image';
// import { ResponsiveEmbed, Image } from 'react-bootstrap';
import { Image } from 'react-bootstrap';



export const Home = () => {
    return (
        <div>

            <Container>
                <Row className="d-flex flex-column flex-md-row flex-lg-row justify-content-around">
                    <Col className="techStrategy" >
                        <div className="techStrategy" style={{}}>
                            <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                    <h2 className="techHeader" style={{ color: '#5497da', textAlign: 'left' }} >
                                        From strategy to deployment and beyond…
                                    </h2>
                                    <div className="">
                                        <div className="wpb_wrapper">
                                            <p className="techMaterial" style={{ color: '#555' }}>
                                                <br />
                                                {/* Once you conceive an idea, the next step is to find
                                                the best technology partner to bring your idea to
                                                life. Our team of developers, software architects,
                                                project managers and other technicians is equipped
                                                with advanced and up-to-date skill sets to cater to
                                                specific needs of your project. We are a full-scale
                                                software development firm that knows the value of
                                                lasting relationships and can combine focused detail-
                                                oriented action with long-term strategic thinking. */}
                                                Silverback has a vast team of diverse talent ranging
                                                from the field of engineering to the field of design
                                                all the way to quality assurance enabling the operations
                                                of a product design and development company. Industry has
                                                a team of dedicated software developers, embedded engineers
                                                and designers that understand the end-to-end integration
                                                and create an enriching experience for the clients.



                                            </p>
                                            <br />
                                            <p>
                                                <a style={{ color: '#5497da' }} href="/about-us/">
                                                    {/* View our technical skill set → */}
                                                </a>
                                            </p>

                                        </div>
                                    </div>
                                    <div className="vc_empty_space" style={{ height: '32px' }}>
                                        <span className="vc_empty_space_inner"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className="techSkill" style={{}}>
                            <Image src={skillset} className='img-fluid' alt="banner" responsive />
                            {/* <ResponsiveEmbed a16by9>
                                <embed href={skillset} />
                            </ResponsiveEmbed> */}
                        </div>

                    </Col>
                </Row>
            </Container>


        </div>
    );
}